<template>
  <div>
    <div class="container">
      <form v-if="loginPage === 'main'" @submit.prevent="login">
        <div class="auth-container d-flex flex-column text-center">
          <h3 class="color-primary head-text">เข้าสู่ระบบ</h3>
          <input
            class="text-big"
            type="email"
            placeholder="อีเมล"
            v-model="userEmail"
            required
          />
          <div class="position-relative">
            <input
              class="w-100 text-big"
              :type="passwordDisplay"
              v-model="userPassword"
              placeholder="รหัสผ่าน"
              required
            />
            <img
              class="password-icon"
              src="../assets/images/showpassword.svg"
              alt=""
              @click="togglePasswordDisplay"
            />
          </div>
          <button class="secondary mb-2" type="submit">
            <p class="text-big">เข้าสู่ระบบ</p>
          </button>
          <p
            class="ml-auto color-primary"
            style="cursor: pointer; width: fit-content;"
            @click="$router.push({ name: 'Forget' })"
          >
            <u>
              ลืมรหัสผ่าน
            </u>
          </p>
          <p class="text-big text-line color-primary">
            <span>หรือ</span>
          </p>
          <button
            class="gg-button position-relative"
            type="button"
            @click="socialGoogleLogin"
          >
            <img
              class="position-absolute"
              src="../assets/images/gg.svg"
              alt=""
            />
            <p class="text-big">เข้าสู่ระบบด้วย Google</p>
          </button>
          <button
            class="fb-button position-relative"
            type="button"
            @click="socialFacebookLogin"
          >
            <img
              class="position-absolute"
              src="../assets/images/fb.svg"
              alt=""
            />
            <p class="text-big">เข้าสู่ระบบด้วย Facebook</p>
          </button>
          <p class="color-primary text-center m-0">
            ยังไม่มีบัญชี
            <span class="register font-weight-bold" @click="goToRegister"
              >สมัครสมาชิก</span
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
export default {
  data() {
    return {
      loginPage: "main",
      userEmail: "",
      userPassword: "",
      passwordDisplay: "password",
    };
  },
  methods: {
    togglePasswordDisplay() {
      if (this.passwordDisplay === "password") {
        this.passwordDisplay = "text";
      } else {
        this.passwordDisplay = "password";
      }
    },
    checkEmail() {
      this.loginPage = "normal";
    },
    reloadLogin() {
      this.loginPage = "main";
    },
    async login() {
      this.$store.dispatch("setLoading", true);
      try {
        await this.$store.dispatch("login", {
          email: this.userEmail,
          password: this.userPassword,
        });
        this.$router.push({ name: "Home" });
      } catch (err) {
        alert(err);
      }
      this.$store.dispatch("setLoading", false);
    },
    goToRegister() {
      this.$router.push({ name: "Register" });
    },
    async socialGoogleLogin() {
      this.$store.dispatch("setLoading", true);
      const provide = new firebase.auth.GoogleAuthProvider();
      try {
        const result = await firebase.auth().signInWithPopup(provide);
        console.log(result);
        const idToken = result.credential.idToken;
        await this.$store.dispatch("firebaseLogin", {
          token: idToken,
          type: "google",
        });
        this.$router.push({ name: "Home" });
      } catch (err) {
        alert(err.message);
      }
      this.$store.dispatch("setLoading", false);
    },
    async socialFacebookLogin() {
      this.$store.dispatch("setLoading", true);
      const provide = new firebase.auth.FacebookAuthProvider();
      // provide.addScope("email");
      // provide.addScope("public_profile");
      try {
        const result = await firebase.auth().signInWithPopup(provide);
        console.log(result);
        const accessToken = result.credential.accessToken;
        await this.$store.dispatch("firebaseLogin", {
          token: accessToken,
          type: "facebook",
        });
        this.$router.push({ name: "Home" });
      } catch (err) {
        alert(err.message);
      }
      this.$store.dispatch("setLoading", false);
    },
  },
  mounted() {
    this.$root.$on("reload-login", this.reloadLogin);
  },
  beforeDestroy() {
    this.$root.$off("reload-login", this.reloadLogin);
  },
};
</script>

<style scoped>
input:focus {
  outline: none;
}

input {
  border: 1px solid #0430ad;
  /* text-align: center; */
  margin-bottom: 20px;
  padding: 10px;
}

button {
  padding: 10px;
  margin-bottom: 20px;
}

button.primary {
  background-color: #0430ad;
  color: #fff;
}

button.secondary {
  color: #fff;
  background-color: #07c5f8;
}

.password-icon {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 10px);
  right: 10px;
  transform: translateY(-50%);
}

.auth-container {
  margin: 100px auto;
  max-width: 370px;
}

.head-text {
  margin-bottom: 50px;
}

.register {
  cursor: pointer;
  text-decoration: underline;
}

.text-line {
  width: 100%;
  text-align: center;
  border-bottom: 3px solid #0430ad;
  line-height: 0.1em;
  margin: 40px 0;
}

.text-line span {
  background: #fff;
  padding: 0 10px;
}

.gg-button {
  border: 1px solid #0430ad;
  color: #0430ad;
  background-color: #fff;
}

.gg-button img {
  left: 10px;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.fb-button {
  border: 1px solid #405793;
  color: #405793;
  background-color: #fff;
}

.fb-button img {
  left: 10px;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
}

/* .forgot-pass {
  text-decoration: underline;
  cursor: pointer;
} */

::-webkit-input-placeholder {
  /* text-align: center; */
  color: #07c5f8;
}

:-moz-placeholder {
  /* Firefox 18- */
  /* text-align: center; */
  color: #07c5f8;
}

::-moz-placeholder {
  /* Firefox 19+ */
  /* text-align: center; */
  color: #07c5f8;
}

:-ms-input-placeholder {
  /* text-align: center; */
  color: #07c5f8;
}
</style>
